import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Block, Flex } from '../../components/styled';
import SwitchLocale from '../../components/switch-locale';

import {
  FooterBottom as FooterBottomContainer,
  FooterTerms,
  FooterTermsMobile,
  FooterCopyright,
  SocialLink,
  FooterLegalLinks,
  FooterLink,
  FidelWhiteLogo,
  FooterLogoContainerMobile,
  FooterDisclaimer,
  MobileFooterDisclaimer,
} from './styled';

import GitHubIcon from './assets/github.svg';
import LinkedInIcon from './assets/linkedin.svg';
import TwitterIcon from './assets/twitter.svg';

const FooterBottom: React.FC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              href
              label
            }
            legalLinks {
              href
              label
            }
          }
        }
      }
    `,
  );

  const icons = {
    Twitter: <TwitterIcon />,
    GitHub: <GitHubIcon />,
    LinkedIn: <LinkedInIcon />,
  };

  const SocialLinks = (
    <Block as={Flex} alignItems="center" breakpoint="0">
      {site.siteMetadata.social.map(({ href, label }) => (
        <SocialLink to={href} key={label}>
          {icons[label]}
        </SocialLink>
      ))}
    </Block>
  );

  const LegalLinks = (
    <FooterLegalLinks>
      {site.siteMetadata.legalLinks.map(({ href, label }, idx) => (
        <li key={idx}>
          <FooterLink to={href}>{label}</FooterLink>
        </li>
      ))}
    </FooterLegalLinks>
  );

  return (
    <FooterBottomContainer>
      <FooterTerms>
        <FidelWhiteLogo />
        <div>
          {LegalLinks}
          <SwitchLocale />
        </div>
      </FooterTerms>
      <FooterTermsMobile>
        {LegalLinks}
        <FooterLogoContainerMobile>
          <FidelWhiteLogo />
          <SwitchLocale />
        </FooterLogoContainerMobile>
      </FooterTermsMobile>
      <FooterCopyright>
        <FooterDisclaimer>
          © Fidel Ltd {new Date().getFullYear()}. Fidel API uses a proprietary
          tokenization system and does not store any card numbers. <br />
          All API requests use TLSv1.2 with end-to-end encryption. Fidel API
          receives transaction authorization messages <br />
          typically within seconds of a successful authorization event.
        </FooterDisclaimer>

        <MobileFooterDisclaimer>
          © Fidel Ltd {new Date().getFullYear()}. Fidel API uses a proprietary
          tokenization system and does not store any card numbers. All API
          requests use TLSv1.2 with end-to-end encryption. Fidel API receives
          transaction authorization messages typically within seconds of a
          successful authorization event.
        </MobileFooterDisclaimer>
        {SocialLinks}
      </FooterCopyright>
    </FooterBottomContainer>
  );
};

export default FooterBottom;
